.CL_PPP_bar_plot{
    width: 100%;
}

.CL_data_outer{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}


@media (max-width: 768px) {
    .CL_PPP_bar_plot{
        width: 700px;
    } 
}