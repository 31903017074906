.card-header{
    margin-top: 0;
    margin-bottom: 0;
}

.text-block-research {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.5); /* Black w/opacity/see-through */
    color: white;
    font-weight: bold;
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 60%;
    height: auto;
    padding: 5px;
    text-align: center;
  }

  .research-main-page {
    margin: 80px 0px 0px 0px;
    min-height: 100vh;
  }

  .research-filter-container {
    display: flex;
    font-size: 0.85em;
    justify-content: space-evenly; 
    flex-wrap: wrap;
    /* padding: 40px 40px; */
    margin: 0px 150px;
  }

 .summaryCardsButton {
    align-self: center;
    border-radius: 10px;
    background: #003057;
    color: white;
    font-size: 16px;
    font-weight: 700;
    border: 0;
    padding: 8px 16px;
}

  .research-filter {
    padding: 0px 10px;
    border-radius: 20px !important;
    margin-bottom: 10px;
  }

  .research-filter:hover {
    cursor: pointer;
  }

  .research-filter-selected {
    background: linear-gradient(#bfb37c 0%,#b3a369 100%);
  }

  .research-summary-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 10px;
  }

  .image-research {
    height: 65vh;
  }

  .cardtextstylingresearch{
    width: 250px;
    position: relative;
}

.button-container{
    position: absolute;
    bottom: 20px;
    margin-top: 40px;
    left: 0;
    right: 0;
}

.cardimgstylingresearch{
  height: 200px;
  width: 250px;
}

.research-modal-background-div {
  z-index: 1000;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
}

.research-modal-container {
  margin: 70px auto;
  padding: 20px;
  background: #fff;
  border-radius: 5px;
  height: 90vh !important;
  width: 70%;
  position: relative;
  transition: all 5s ease-in-out;
  overflow: scroll;
}

  .carousel-indicators li { visibility: hidden; }
  
  @media (max-width: 768px) {
      .text-block-research {
        top: 30%;
        height: auto;
      }

      .research-filter-container {
        display: flex;
        font-size: 0.85em;
        justify-content: space-evenly; 
        flex-wrap: wrap;
        /* padding: 40px 40px; */
        margin: 0px 10px;
      }

      .cardimgstylingresearch{
        height: 160px;
        width: 150px;
    }

    .cardtextstylingresearch{
        width: 300px;
        position: relative;
    }

    .image-research {
      height: 50vh;
    }
  }

  @media (min-width: 1920px) {
    .image-research {
      height: 50vh;
    }

    .text-block-research {
      top: 30%;
    }
  }