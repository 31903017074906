.qcf{
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
}

.text-block-qcf-text {
    width: 65%;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.1); /* Black background with 0.5 opacity */
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-items: center;
    padding: 10px;
}

.text-block-qcf {
    background:  rgb(0, 0, 0); /* Fallback color */
    background:  rgba(255, 255, 255, 0.75 ); /* Black background with 0.5 opacity */
    color: white;
    position: absolute;
    top: 35px;
    width:100%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    border-color: black;
    margin-top: 40px;
}

.CL_QCF_text{
    margin: 20px;
  }
  
.CL_qcf_logo{
    width: auto;
    margin-top: 20px;
}

.QCFimg{
    height: 100vh;
}

@media (max-width: 768px) {
    .CL_qcf_logo{
        width: 100%;
        margin-top: 30px;
    }
    .text-block-qcf {
        top: 20px;
    }

    .text-block-qcf-text {
        width: 70%;
        height: auto;
        color: white;
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        justify-items: center;
        font-size: 0;
    }
    
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {
    .text-block-qcf-text {
        width: 70%;
    }
}

@media (min-width: 1920px) {
    .text-block-qcf-text {
        width: 50%; 
      }
    }
