.people{
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    padding: 0px 30px 0px;
    color: black;
}


.CL_people_header_texts {
    font-size: 15px;
    margin-left: 30px;
}
  
.CL_people_header_people{
    font-size: 20px;
    margin-left: 50px;
}

.CL_img-container {
    /* overflow: hidden; */
    height: 50px;
    max-height: 100vh;
    position: relative;
}

.text-block {
    width: 70%;
    /* background: rgb(0, 0, 0); Fallback color */
    /* background: rgba(0, 0, 0, 0.6); Black background with 0.5 opacity */
    color: black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-items: center;
    justify-items: center;
    padding: 10px;
}

.cardimgstyling{
    height: 250px;
    width: 200px;
    object-fit: cover;
}

.cardtextstyling{
    width: 200px;
}

.people-list-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.Chava{
    /* height: 348px; */
    width: 348px;
    object-fit: contain;
    object-position: top;
  }

.Prof_Chava_designations{
    text-align: left;
}

.Prof_Chava_title_mobile{
    display: none;
}

.Chava_title{
    font-size: 35px;
    color: #54585A;
}

.Chava_link_buttons{
    padding: 0px;
    border: 0px;
    margin: 12px; 
    background-color: white;
}

.Chava_link_buttons_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1px;
}

.buttonIcons{
    height: 25px;
    /* background-color: white; */
}

@media (max-width: 768px) {
    .Prof_Chava_designations{
        text-align: left;
    }
    .Chava{
        height: 180px;
        width: 250px;
        padding-right: 3px;
      }
    .CL_img-container {
        height: 20vh;
        position: relative;
    }
    
    .CL_img {
        overflow: hidden;
        height: 100%;
        max-height: 100%;
        position: relative;
    }

    .Prof_Chava_title_mobile{
        display: flex;
        margin-top: 20px;
    }

    .Prof_Chava_description {
        display: none;
    }

    .text-block {
        width: 70%;
        height: auto;
        color: black;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        align-items: center;
        justify-items: center;
        font-size: 0;
    }

    .cardimgstyling{
        height: 190px;
        width: 150px;
    }

    .cardtextstyling{
        width: 155px;
    }
    .CL_h5_new{
        font-size: 2.0rem;
    }
    .CL_h6_new{
        font-size: 1.5rem;
    }
    .buttonIcons{
        height: 18px;
        /* background-color: white; */
    }
}

@media only screen 
and (min-device-width: 768px) 
and (max-device-width: 1024px) 
and (-webkit-min-device-pixel-ratio: 1) {
    .text-block {
        width: 70%;
    }
}


@media (min-width: 1920px) {
    .text-block {
        width: 30%; 
      }
    }
