.text-block-data {
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0); /* Black background with 0.5 opacity */
    color: white;
    position: absolute;
    top: 30%;
    width:auto;
    left: 35%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin:20px;
  }

.CL_data_outer{
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
}

.CL_data_card_title{
    height: 2rem;
}

.CL_data_img{
    height: 50vh;
}

@media (max-width: 768px) {
    .CL_data_card_title{
        height: 3rem;
    }   
    .CL_data_img{
        height: 220px;
    }
    .text-block-data{
        top: 20%;
    }
}