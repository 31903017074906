.CL_contactus{
    margin-top: 40px;

}
.CL_contactus_btn{
    border-radius: 4px;
}

.CL_outer_box_contact{
    border-width: 2px;
    border-color: gray;
    border-radius: 4px;
}
.CL_contactus_box{
    padding-left: 0px;
    padding-right: 0px;
}

.CL_contactus_img{
    height: 60vh;    
}

@media (max-width: 768px) {
    .CL_contactus{
        margin-top: 60px;
    }
    .CL_contactus_img{
        height: 140px;
    }
    .CL_contactus_box{
        padding-left: 30px;
        padding-right: 30px;
    }
}
