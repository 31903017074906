.App {
  text-align: center;
}

:root {
  --page-padding:237px;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

body {
  font-family: "Roboto", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
  font-weight: 300;
  color: #262626;

}

.notFoundContainer {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding-left: 80px;
  height: 500px;
}


.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/*May be this is causing blue color header text on deployed website*/
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a, u {
  text-decoration: none !important;
}

a:hover{
  transform: scale(1.00);
}


.sec-heading{
  text-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}


.widget {
  background-color: #343a40;
  padding: 30px;
  margin: 5px;
}

.dropdown{
  margin-left: 0px;
}

.btn {
  @include heading-font;
  font-weight: 700;
}

.btn-xl {
  font-size: 18px;
  padding: 20px 40px;
}

.researchname {
  justify-content: center;
  align-items: center;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 85vh;
  align-items: center;
  justify-items: center; /* adjusted */
}

.wrapperimage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 85vh;
  background-size: cover;
}

.wrapperresearch {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 50vh;
  align-items: center;
  justify-items: center;
}

.wrapperresearchimage {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 50vh;
}


.news{
  overflow-x: hidden;
}

.research{
  overflow-x: hidden;
}

.CL_contactus{
  overflow-x: hidden;
  overflow-y: hidden;
}

.Logo {
  grid-area: logo;
  height: 70px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90vh;
  align-items: center;
  justify-items: center; /* adjusted */
}

.wrapperimage {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 90vh;
  background-size: cover;
}

.researchcard{
  display: flex;
  flex-flow: row wrap;
}

.table{
  border-collapse: collapse;
}

.wrapper2 {
  grid-template-rows: 100vh;
}

.text-block-btn {
  color: white;
  position: absolute;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.a{
  text-decoration:none
}

.noHover{
  pointer-events: none;
}

.padding-0{
    padding-right:0;
    padding-left:0;
}

.card-img{
  width: '30vw';
  height: '30vw';
}



#links {
  color: #b3b3b3;
  font-size: 85%;
  font-weight: normal;
  text-align: center;
}


.dropdown-menu a{
  color: #FFFFFF !important;
}

.dropdown-menu a:hover{
  background-color: #343a40 !important;
}

.a { color: inherit; } 

.blur{
  filter: blur(8px);
  -webkit-filter: blur(8px);
}

