.text-block-card {
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
    width: 90%;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.6); /* Black background with 0.5 opacity */
    color: white;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
.text-block-card {
    font-family: Arial, Helvetica, sans-serif;
    height: auto;
    width: 200px;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.6); /* Black background with 0.5 opacity */
    color: white;
    position: absolute;
    bottom: 0%;
    left: 50%;
    transform: translate(-50%, -50%);
    }

    .text-block-big-card{
    bottom:   5%;
    height: auto;
    }
}    

.text-block-about {
    width: 67.5%;
    background: rgb(0, 0, 0); /* Fallback color */
    background: rgba(0, 0, 0, 0.6); /* Black background with 0.5 opacity */
    color:white;
    position: relative;
    margin: auto;
    padding: 5px;
    transform: translateY(-100%);   
}

.CL_text_about{
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    margin: 10px;
}

.cardtextstylingaboutresearch{
    width: 400px;
}

.cardimgstylingaboutresearch{
  height: 400px;
  width: 400px;
}
  
.eventimg{
    height:60vh;
}
    
@media (max-width: 768px) {
    .text-block-about {
        width: 100%;
        color: white;
        transform: translateY(-80%); 
        }

    .eventimg{
    height:50vh;
    }
}
    
@media (min-width: 768px)
and (max-width: 1280px) {
    .text-block-about {
        width: 100%;
        color: white;
        transform: translateY(-80%); 
    }
    
    .eventimg{
    height:90vh;
    }
}

@media (min-width: 1920px) {
    .text-block-about {
    width: 35%;
    transform: translateY(-250%); 
    }
}

.eventcardimgstyling{
    height: 500px;
    width: 350px;
}

@media (max-width: 768px) {
    .eventcardimgstyling{
        height: 400px;
        width: 300px;
    }
}