.logo-container {
    height: 100px;
    display: flex;
    flex-direction: row;
    align-items: center; /* This centers vertically */
    justify-content: center; /* This centers horizontally */
}

.logo-container a {
    width: 38px;
    height: 50px;
    align-items: center;
    margin-right: 38px;
    flex-shrink: 0;
}

@media all and (max-width: 996px) {
    .logo-container {
        height: 100px;
        display: flex;
        flex-direction: row;
        align-items: center; 
        justify-content: center;
        margin: 27px 0 150px 5px;
    }

    .logo-container a {
        width: 40px;
        height: 50px;
        align-items: center;
        flex-shrink: 0;
        margin: 8px;
    }
}
.gt-background-gt-footer-alt {
    width: 100%;
    height: 100px;
    background: var(--gtweb-navy-blue)
}

.gt-background-gt-footer {
    background: linear-gradient(to left,var(--gtweb-medium-gold),var(--gtweb-primary-gold))
}

.gt-gt-footer-farleftcol {
    padding-left: 10px;
    box-sizing: border-box;
    margin-top: 6px;
    margin-bottom: 6px
}

.gt-gt-footer {
    margin: auto;
    padding: 10px;
    font-family: "Roboto";
    font-size: 85%;
    font-weight: 500;
    overflow: auto;
    padding-top: 16px;
}

.gt-gt-footer-legallinks a {
    border-bottom: solid 1px;
    font-weight: 600;
  }

.gt-gt-footer-legalcolumns {
    margin-top: 6px;
    margin-bottom: 6px;
    padding: 4px 0;
    box-sizing: border-box;
    line-height: 1.5;
}

@media all and (min-width: 966px) {
    .gt-gt-footer-farleftcol {
        float:left;
        width: 25%
    }
}

.gt-gt-footer-institute {
    font-family: "Roboto Condensed","Helvetica Neue","Helvetica","Arial","sans-serif";
    font-size: 110%;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: normal;
    text-align: left;
}

.gt-gt-footer-address {
    border-left: solid 1px var(--gtweb-accent-gold);
    padding-left: 12px;
    margin-bottom: 16px
}

.gt-gt-footer-address A:link{
    border-bottom: solid 1px;
}

.gt-gt-footer-legalcolumns UL {
    padding: 0 0 0 10px;
    margin: 10px;
    box-sizing: border-box;
    border-left: solid 1px var(--gtweb-accent-gold);
    
}

.gt-gt-footer-legalcolumns UL LI {
    display: block;
    font-size: 1.35em;
    text-align: left;
}

@media all and (min-width: 966px) {
    .gt-gt-footer-legalcolumns {
        float:left;
        width: 50%
    }

    .gt-gt-footer-legalcolumns UL {
        float: left;
        width: 50%
    }

    .gt-gt-footer-legalcolumns UL LI {
        white-space: nowrap;
        padding: 0 0 0 12px;
        font-size: unset
    }
}

.gt-gt-footer-right {
    padding: 12px 8px 0 6px;
    margin-left: 16px;
    font-weight: 500;
}

@media all and (min-width: 966px) {
    .gt-gt-footer-right {
        margin-left:0;
        float: right
    }
}

.gt-gt-footer-copyright {
    font-weight: 500;
    font-size: 80%;
    margin: 2px 0 10px;
}

:root {
    --gtweb-accent-gold: #d2c79f;
    --gtweb-primary-gold: #b3a369;
    --gtweb-gradient-gold: #bfb37c;
    --gtweb-medium-gold: #a4925a;
    --gtweb-heading-gold: #958447;
    --gtweb-accessible-gold: #857437;
    --gtweb-background-page: #fff;
    --gtweb-black-text: #333;
    --gtweb-white-text: #fff;
    --gtweb-sitefooter-grey: #262626;
    --gtweb-navy-blue: #003057;
    --gtweb-navy-darkblue: #001840;
    --gtweb-link-blue: #004f9f;
    --gtweb-linkhover-blue: #1879db;
    --gtweb-linkvisit-mauve: #730053;
    --gtweb-pimile-light: #f2f3f1;
    --gtweb-buzz-gold: #eeb211
}

FOOTER#gt-footer A:link {
    color: var(--gtweb-sitefooter-grey);
    border-bottom-color: #000
}


